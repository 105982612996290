import { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { formatCurrency } from "util/formatCurrency";
import PaymentPasswordInput from "components/payment/PaymentPasswordInput";
import { getCardListAPI } from "api/cardApi";
import Cookies from "js-cookie";
import { PaymentApproveResponseType } from "types/shoppingmall/paymentType";
import {
  postPaymentApproveAPI,
  postPaymentDemandAPI,
} from "api/shoppingmallApi";
import { PaymentDemandType } from "types/shoppingmall/paymentType";

interface Card {
  cardId: string;
  cardImage: string;
  cardLastNum: string;
  cardName: string;
  cardType: "CHECK" | "CREDIT";
}

interface CartType {
  id: string;
  itemTitle: string;
  imgUrl: string;
  price: number;
  oriPrice: number;
  sale: number;
  count: number;
}

export default function Payment() {
  const [paymentAmount, setPaymentAmount] = useState<string>("1000");
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [cards, setCards] = useState<Card[]>([]);
  const [accessToken, setAccessToken] = useState<string>("");
  const [cardAllocations, setCardAllocations] = useState<{
    [key: string]: string;
  }>({});
  const [selectedCards, setSelectedCards] = useState<Set<string>>(new Set());
  const [isAutoDistribute, setIsAutoDistribute] = useState<boolean>(true);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [cartList, setCartList] = useState<CartType[]>([]);
  const [transactionId, setTransactionId] = useState<string>("");
  const [shoppingMall, setShoppingMall] = useState<string>("");
  const [installments, setInstallments] = useState<{ [key: string]: number }>(
    {},
  );

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const amount = queryParams.get("amount") || "0";
    const mall = queryParams.get("mall") || "";
    console.log("mall : ", mall);

    setPaymentAmount(amount);
    setShoppingMall(mall);

    const storedCartList = localStorage.getItem(`${mall}_cartList`);
    if (storedCartList) {
      setCartList(JSON.parse(storedCartList));
    }

    const fetchCards = async () => {
      try {
        const token = Cookies.get("accessToken") || "";
        setAccessToken(token);

        if (!token) {
          alert("로그인 해주세요!!");
          navigate("/login");
          return;
        }

        const data = await getCardListAPI(token);
        setCards(data.cardList);

        if (data.cardList.length > 0) {
          setCardAllocations({ [data.cardList[0].cardId]: amount });
          setSelectedCards(new Set([data.cardList[0].cardId]));
        } else {
          alert("카드 등록을 해주세요!");
          navigate("/card/management");
        }
      } catch (error) {
        console.error("카드 목록 가져오기 실패:", error);
      }
    };

    fetchCards();
  }, [location.search]);

  const handleEqualDistribution = () => {
    if (selectedCards.size === 0) {
      alert("카드를 선택해주세요.");
      return;
    }

    const totalAmount = parseFloat(paymentAmount);
    const manualAllocatedAmount = Object.entries(cardAllocations)
      .filter(
        ([cardId]) =>
          selectedCards.has(cardId) && parseFloat(cardAllocations[cardId]) > 0,
      )
      .reduce((acc, [, amount]) => acc + parseFloat(amount), 0);

    const cardsWithoutManualAllocation = Array.from(selectedCards).filter(
      (cardId) => parseFloat(cardAllocations[cardId]) === 0,
    );

    const remainingAmount = totalAmount - manualAllocatedAmount;
    const basicAmount =
      Math.floor(remainingAmount / cardsWithoutManualAllocation.length / 10) *
      10;

    const updatedAllocations = { ...cardAllocations };

    cardsWithoutManualAllocation.forEach((cardId) => {
      updatedAllocations[cardId] = basicAmount.toString();
    });

    const remainingAfterBasic =
      remainingAmount - basicAmount * cardsWithoutManualAllocation.length;

    if (remainingAfterBasic !== 0) {
      updatedAllocations[cardsWithoutManualAllocation[0]] = (
        parseFloat(updatedAllocations[cardsWithoutManualAllocation[0]]) +
        remainingAfterBasic
      ).toString();
    }

    setCardAllocations(updatedAllocations);
  };

  const handleCardAmountChange = (cardId: string, amount: string) => {
    let normalizedAmount = isNaN(parseFloat(amount))
      ? "0"
      : parseFloat(amount).toString();

    // 입력된 금액이 결제 총액(paymentAmount)보다 크면 제한
    if (parseFloat(normalizedAmount) > parseFloat(paymentAmount)) {
      normalizedAmount = paymentAmount;
    }

    // 상태 업데이트를 위해 임시 변수를 사용
    const updatedAllocations = { ...cardAllocations };

    if (isAutoDistribute) {
      // 자동 분배가 켜져 있을 때: 다른 카드들의 금액을 0으로 설정하고 자동 분배를 끄기
      Object.keys(updatedAllocations).forEach((id) => {
        if (id !== cardId) {
          updatedAllocations[id] = "0";
        }
      });
      setIsAutoDistribute(false);
    }

    // 현재 카드의 금액 업데이트
    updatedAllocations[cardId] = normalizedAmount;

    // 상태를 업데이트
    setCardAllocations(updatedAllocations);

    // 선택된 카드 업데이트
    setSelectedCards((prev) => {
      const updatedSet = new Set(prev);
      if (parseFloat(normalizedAmount) > 0) {
        updatedSet.add(cardId);
      } else {
        updatedSet.delete(cardId);
      }
      return updatedSet;
    });
  };

  const handleCheckboxChange = (cardId: string, checked: boolean) => {
    if (checked) {
      setSelectedCards((prev) => {
        const updatedSet = new Set(prev).add(cardId);

        // 카드가 추가된 후 자동 분배가 켜져 있으면 끄기
        if (isAutoDistribute) {
          setIsAutoDistribute(false);
        }

        if (updatedSet.size === 1) {
          // 카드가 하나 선택된 경우 자동 분배 켜기
          setIsAutoDistribute(true);
          setCardAllocations({ [cardId]: paymentAmount });
        } else if (updatedSet.size > 1) {
          // 두 개 이상 선택된 경우 자동 분배 끄기
          setIsAutoDistribute(false);
          const updatedAllocations = { ...cardAllocations };
          updatedSet.forEach((id) => {
            updatedAllocations[id] = "0";
          });
          setCardAllocations(updatedAllocations);
        }

        return updatedSet;
      });
    } else {
      setSelectedCards((prev) => {
        const updatedSet = new Set(prev);
        updatedSet.delete(cardId);

        // 먼저 선택 해제된 카드의 값을 0으로 설정
        const updatedAllocations = { ...cardAllocations };
        updatedAllocations[cardId] = "0"; // 선택 해제된 카드의 값 설정

        // 선택된 카드가 줄어들면 모든 카드의 값을 0으로 설정
        updatedSet.forEach((id) => {
          updatedAllocations[id] = "0";
        });
        setCardAllocations(updatedAllocations); // 모든 할당 값 업데이트

        // 카드가 하나만 남으면 자동 분배 켜기
        if (updatedSet.size === 1) {
          setIsAutoDistribute(true);
          const remainingCardId = Array.from(updatedSet)[0];
          setCardAllocations({ [remainingCardId]: paymentAmount });
        } else if (updatedSet.size < prev.size) {
          // 카드가 줄어들었을 때 자동 분배 끄기
          if (isAutoDistribute) {
            setIsAutoDistribute(false);
          }
        }

        return updatedSet;
      });
    }
  };

  const proceedPay = async () => {
    try {
      const paymentCards = Object.entries(cardAllocations)
        .map(([cardId, amount]) => ({
          cardId,
          chargePrice: parseFloat(amount),
          installment: installments[cardId] || 1,
        }))
        .filter((card) => card.chargePrice > 0);

      const paymentData: PaymentDemandType = {
        storeName: shoppingMall,
        totalPrice: parseFloat(paymentAmount),
        detail:
          cartList && `${cartList[0].itemTitle} 외 ${cartList.length - 1}개`,
        paymentCards,
      };

      console.log("paymentData", paymentData);

      const data = await postPaymentDemandAPI(accessToken, paymentData);
      console.log("결제 요청 성공:", data);
      const transactionId = data.result.transactionId;
      setTransactionId(transactionId);
    } catch (error) {
      console.error("결제 요청 실패:", error);
    }
  };

  const handlePayment = () => {
    // 0원인 카드의 선택을 해제
    setSelectedCards((prev) => {
      const updatedSet = new Set(prev);
      Object.entries(cardAllocations).forEach(([cardId, amount]) => {
        if (updatedSet.has(cardId) && parseFloat(amount) === 0) {
          updatedSet.delete(cardId);
        }
      });
      return updatedSet;
    });

    // 선택된 카드 업데이트 후 로직 실행
    setTimeout(() => {
      // 선택된 카드들에 대한 금액 합산
      const totalAllocatedAmount = Object.entries(cardAllocations)
        .filter(([cardId]) => selectedCards.has(cardId)) // 선택된 카드만 필터링
        .reduce((acc, [, amount]) => acc + parseFloat(amount), 0);

      // 모든 카드의 금액이 0보다 큰지 확인
      const allAmountsValid = Object.entries(cardAllocations)
        .filter(([cardId]) => selectedCards.has(cardId))
        .every(([, amount]) => parseFloat(amount) > 0);

      if (!allAmountsValid) {
        // 0원인 카드는 이미 선택 해제되었으므로, 더 이상 알림을 보여주지 않음
        return;
      }

      // 선택된 카드의 분배된 금액 합과 결제 금액이 일치하는지 확인
      if (totalAllocatedAmount !== parseFloat(paymentAmount)) {
        alert("각 카드에 분배된 금액의 합이 총 결제 금액과 일치해야 합니다.");
        return;
      }

      try {
        proceedPay();
        console.log("결제 처리 시작:", cardAllocations);
        setIsProcessing(true);
        setShowPassword(true);
      } catch (err: any) {
        alert("결제 과정 중 오류가 발생했습니다.");
      }
    }, 0); // setTimeout을 사용하여 선택 상태 업데이트 후 로직 실행
  };

  const notifyParentAndClose = (data: PaymentApproveResponseType) => {
    if (window.opener && typeof window.opener.notifyPaymilli === "function") {
      window.opener.notifyPaymilli(data);
    }
    window.close();
  };

  const restorePayment = () => {
    setIsProcessing(false);
    setShowPassword(false);
  };

  const handlePasswordSubmit = async (password: string) => {
    try {
      const data = await postPaymentApproveAPI(
        accessToken,
        transactionId,
        password,
      );

      console.log("api 데이터", data);

      setIsProcessing(true);
      setShowPassword(false);

      notifyParentAndClose(data);
    } catch (error: any) {
      // `error?.response?.data`가 있는지 먼저 확인
      const code = error?.response?.data?.code;
      console.log(code, "의 타입 : ", typeof code);

      // 에러 메시지를 `response`가 있을 때와 없을 때로 나누어 처리
      let errorMessage =
        error?.response?.data?.message ||
        "결제 중 문제가 발생했습니다. 관리자에게 문의해주세요";

      // code가 402인 경우 카드 정보 확인 메시지 추가
      if (code === 402) {
        const cardName = error?.response?.data?.result?.cardName;
        const cardNumber = error?.response?.data?.result?.cardNumber;

        if (cardName && cardNumber) {
          console.log(cardName, cardNumber);
          errorMessage += `\n${cardName}(${cardNumber})를 확인해주세요!`;
        } else {
          console.log("Card information is missing in the response data.");
        }
      }

      // 사용자에게 경고창으로 에러 메시지 표시
      alert(errorMessage);
    }
  };

  return (
    <>
      {showPassword ? (
        <PaymentPasswordInput
          onSubmit={handlePasswordSubmit}
          restorePayment={restorePayment}
        />
      ) : (
        <Container>
          <Header>
            <img
              src="/img/logo_paymilli-removebg.png"
              width={100}
              alt="Pay-milli logo"
            />
            <h2>결제 페이지</h2>
          </Header>

          <Content>
            <DescriptionContainer>
              <div>
                <h4>최종 결제 금액 : {formatCurrency(paymentAmount)}원</h4>
                <p>결제를 원하는 카드를 선택하고 금액을 분배하세요.</p>
              </div>
              <DistributionButton
                onClick={() => {
                  if (isAutoDistribute) {
                    // 자동 분배 끄기 시 모든 카드의 금액을 0으로 설정
                    const updatedAllocations = { ...cardAllocations };
                    Array.from(selectedCards).forEach((cardId) => {
                      updatedAllocations[cardId] = "0";
                    });
                    setCardAllocations(updatedAllocations);
                  } else {
                    // 자동 분배 켜기 시 균등 분배 실행
                    handleEqualDistribution();
                  }
                  setIsAutoDistribute((prev) => !prev);
                }}
              >
                {isAutoDistribute ? "비우기" : "MILLI!!"}
              </DistributionButton>
            </DescriptionContainer>

            <CardList>
              {cards &&
                cards.map((card) => (
                  <CardAllocation
                    key={card.cardId}
                    selected={selectedCards.has(card.cardId)}
                    onClick={(e) => {
                      // 금액 입력 필드에서 발생한 이벤트는 무시
                      if (
                        (e.target as HTMLElement).closest(
                          ".amount-input-wrapper",
                        )
                      ) {
                        e.stopPropagation();
                        return;
                      }

                      const isSelected = selectedCards.has(card.cardId);
                      handleCheckboxChange(card.cardId, !isSelected);
                    }}
                  >
                    <CardCheckboxWrapper>
                      <input
                        type="checkbox"
                        checked={selectedCards.has(card.cardId)}
                        onChange={(e) =>
                          handleCheckboxChange(card.cardId, e.target.checked)
                        }
                        onClick={(e) => e.stopPropagation()} // 체크박스 클릭 시 카드 전체 클릭 이벤트 막기
                      />
                      <CardImage
                        src={card.cardImage}
                        alt="카드 이미지"
                        onClick={(e) => {
                          e.stopPropagation(); // 이미지 클릭 시 상위 이벤트 전파 막기
                          const isSelected = selectedCards.has(card.cardId);
                          handleCheckboxChange(card.cardId, !isSelected); // 이미지 클릭 시 체크 상태 변경
                        }}
                      />
                    </CardCheckboxWrapper>
                    <CardInfo>
                      <RowContainer>
                        <CardName>{card.cardName}</CardName>
                        <CardType>{card.cardType}</CardType>
                      </RowContainer>
                      <CardNumber>**** **** **** {card.cardLastNum}</CardNumber>

                      <AmountInputWrapper className="amount-input-wrapper">
                        {card.cardType === "CREDIT" &&
                          selectedCards.has(card.cardId) && (
                            <InstallmentDropdown
                              value={installments[card.cardId] || 0}
                              onChange={(e) =>
                                setInstallments((prev) => ({
                                  ...prev,
                                  [card.cardId]: Number(e.target.value),
                                }))
                              }
                            >
                              <option value={1}>일시불</option>
                              <option value={3}>3개월</option>
                              <option value={6}>6개월</option>
                              <option value={9}>9개월</option>
                              <option value={12}>12개월</option>
                            </InstallmentDropdown>
                          )}
                        <AmountInput
                          type="text"
                          value={formatCurrency(
                            cardAllocations[card.cardId] || "0",
                          )}
                          onChange={(e) => {
                            if (isAutoDistribute) {
                              // 자동 분배를 끄기
                              setIsAutoDistribute(false);

                              // 현재 입력받는 카드 외의 나머지 카드들을 0으로 설정
                              const updatedAllocations = { ...cardAllocations };
                              for (const id in updatedAllocations) {
                                if (id !== card.cardId) {
                                  updatedAllocations[id] = "0";
                                }
                              }
                              setCardAllocations(updatedAllocations);
                            }

                            // 입력값을 숫자로 변환하고 총 결제 금액을 넘지 않도록 제한
                            let value = e.target.value.replace(/[^0-9]/g, "");
                            if (parseFloat(value) > parseFloat(paymentAmount)) {
                              value = paymentAmount;
                            }

                            handleCardAmountChange(card.cardId, value);
                          }}
                          disabled={!selectedCards.has(card.cardId)}
                        />
                        <CurrencySymbol>원</CurrencySymbol>
                      </AmountInputWrapper>
                    </CardInfo>
                  </CardAllocation>
                ))}
            </CardList>
          </Content>

          <SubmitButton onClick={handlePayment} disabled={isProcessing}>
            {isProcessing ? "결제 처리 중..." : "결제하기"}
          </SubmitButton>
        </Container>
      )}
    </>
  );
}

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 12px;
  background-color: #f9f9f9;
  border-radius: 8px;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;
const Header = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;

  img {
    margin-right: 16px;
  }

  h2 {
    color: var(--main-color);
  }
`;

const Content = styled.div`
  margin-bottom: 20px;
`;

const AmountInputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  select {
    margin-right: 8px;
  }
`;

const InstallmentDropdown = styled.select`
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
`;

const AmountInput = styled.input`
  width: 90px;
  text-align: right;
  border: none;
  background: transparent;
  border-bottom: 2px solid #ccc;
  font-size: 18px;
  padding: 0px 8px;
  color: #333;
  &:disabled {
    color: #aaa;
  }
`;

const CurrencySymbol = styled.span`
  font-size: 16px;
  margin-left: 5px;
  color: #333;
`;

const DescriptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  h4,
  h5 {
    margin: 0;
    color: var(--main-color);
  }

  p {
    margin-top: 8px;
    color: #555;
  }
`;
const CardCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  input[type="checkbox"] {
    width: 20px;
    height: 20px;
    margin-right: 16px;
    cursor: pointer;
  }
`;

const CardList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 350px;
  overflow-y: auto;
  margin: 12px 0px;
  margin-bottom: 30px;
`;

const CardAllocation = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  padding: 12px;
  background-color: white;
  border: ${(props) =>
    props.selected ? "2px solid var(--main-color)" : "1px solid #ddd"};
  border-radius: 10px;
  box-shadow: ${(props) =>
    props.selected ? "0 4px 8px rgba(0, 0, 0, 0.15)" : "none"};
  transition: all 0.3s ease;

  label {
    display: flex;
    align-items: center;

    width: 100%;
  }
`;

const CardImage = styled.img`
  width: 80px;
  height: 50px;
  margin-right: 16px;
  border-radius: 8px;
`;

const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const CardName = styled.span`
  font-weight: 400;
  color: black;
`;
const CardType = styled.span`
  color: var(--gray);
`;

const CardNumber = styled.span`
  color: black;
  margin-bottom: 8px;
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 15px;
  background-color: var(--main-color);
  border: none;
  border-radius: 8px;
  color: white;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: var(--);
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const DistributionButton = styled.button`
  width: 100px;
  height: 45px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: var(--main-color);
  }
`;
